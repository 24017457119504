
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import {
  IPaymentMethod,
  IPaymentMethodCreateRequest,
  IPaymentMethodRequest,
  IPaymentMethodUpdateRequest
} from "@/types/payment_method";
import { PaymentMethodRequest } from "@/models/payment_method";

@Component({})
export default class extends Vue {
  //payment_method
  @Action("payment_method/adminGet")
  public getPaymentMethod!: (payment_method_id: number) => void;

  @Action("payment_method/adminCreate")
  public create!: (params: IPaymentMethodCreateRequest) => boolean;

  @Action("payment_method/adminUpdate")
  public update!: (data: {
    payment_method_id: number;
    params: IPaymentMethodUpdateRequest;
  }) => boolean;

  @Action("payment_method/adminDelete")
  public deletePaymentMethod!: (payment_method_id: number) => boolean;

  @Getter("payment_method/single")
  public payment_method!: IPaymentMethod;

  @Mutation("payment_method/clear")
  public clear!: () => void;

  //パラメータ定義
  public params: IPaymentMethodRequest = new PaymentMethodRequest();

  //変数定義
  public payment_method_id = 0;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;

  //ルール設定
  public rules = {
    name: [(v: string) => !!v || "支払方法は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.payment_method_id = Number(this.$route.params.payment_method_id);
    this.clear();

    // 編集時
    if (this.payment_method_id) {
      await this.getPaymentMethod(this.payment_method_id);

      this.setDefault();
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    this.params.createFromPaymentMethod(this.payment_method);
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.payment_method_id) {
      result = await this.update({
        payment_method_id: this.payment_method_id,
        params: this.params
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.submit_dialog = false;
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deletePaymentMethod(this.payment_method_id)) {
      this.$router.push(`/admin/payment_method/list`);
    }
  }
}
